import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React, { useCallback, useMemo, useState } from 'react';

import { openSaveFilterSubMenu } from '../../../../shared/foreground/cmdPalette';
import { updateFilteredView } from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/filteredView';
import { setFilterQueryToCreate } from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/filtereredView';
import { addTagToQuery, removeTagFromQuery } from '../../../../shared/foreground/utils/tagQueriesHelpers';
import type { FilteredView } from '../../../../shared/types';
import type { GlobalTagsObject } from '../../../../shared/types/tags';
import Button from '../Button';
import ViewsIcon from '../icons/16StrokeViews';
import ChevronDownIcon from '../icons/ChevronDownSmallIcon';
import styles from './BulkTagsViewsDropdown.module.css';
import { getSeparatorOption, getTitleOption } from './docOptions';
import { Dropdown, DropdownOptionType } from './Dropdown';

const Trigger = () => {
  return (
    <Button className={styles.manageViewsButton} variant="secondary">
      <ViewsIcon className={styles.viewsIcon} />
      Manage views
      <ChevronDownIcon />
    </Button>
  );
};

export default React.memo(function BulkTagsViewsDropdown({ selectedIds, views, associatedViews, globalTagsObject }: {selectedIds: string[]; views: FilteredView[]; associatedViews: (FilteredView & { isAssociatedWithGlobalTags: boolean; })[]; globalTagsObject: GlobalTagsObject;}) {
  const [isOpen, setIsOpen] = useState(false);

  const TriggerElement =
    <DropdownMenu.Trigger>
      <Trigger />
    </DropdownMenu.Trigger>;

  const removeTagFromView = useCallback((view: FilteredView) => {
    let newQuery = view.query;

    for (const tagId of selectedIds) {
      const tag = globalTagsObject[tagId];
      newQuery = removeTagFromQuery({ query: newQuery, tagName: tag.name });
    }

    updateFilteredView({
      ...view,
      query: newQuery,
    }, { userInteraction: 'unknown' });
  }, [globalTagsObject, selectedIds]);

  const addTagToView = useCallback((view: FilteredView) => {
    let newQuery = view.query;

    for (const tagId of selectedIds) {
      const tag = globalTagsObject[tagId];
      newQuery = addTagToQuery({ query: newQuery, tagName: tag.name });
    }

    updateFilteredView({
      ...view,
      query: newQuery,
    }, { userInteraction: 'unknown' });
  }, [selectedIds, globalTagsObject]);

  const viewsOptions = useMemo(() => views?.map((view) => {
    const associatedView = associatedViews?.find((_view) => _view.id === view.id);
    const checked = Boolean(associatedView);

    return {
      type: DropdownOptionType.Checkbox,
      name: view.name || 'No name',
      checked,
      isMinusIcon: !associatedView?.isAssociatedWithGlobalTags,
      setChecked: (isChecked: boolean) => {
        if (isChecked) {
          addTagToView(view);
        } else {
          removeTagFromView(view);
        }
      },
    };
  }) ?? [], [associatedViews, views, addTagToView, removeTagFromView]);

  const options = useMemo(() => [
    getTitleOption(`Include documents from ${selectedIds.length} selected tags in the following views`, styles.itemTitle),
    getSeparatorOption(),
    {
      type: DropdownOptionType.ScrollableItems,
      childOptions: viewsOptions,
    },
    getSeparatorOption(),
    {
      type: DropdownOptionType.Item,
      isCreate: true,
      name: 'Create a new view from selected tags',
      className: styles.createViewOption,
      onSelect: () => {
        const queryToCreate = selectedIds.map((tagId) => {
          const tag = globalTagsObject[tagId];
          return `tag:"${tag.name}"`;
        }).join(' OR ');

        setFilterQueryToCreate(queryToCreate);
        openSaveFilterSubMenu();
      },
    },
  ], [globalTagsObject, selectedIds, viewsOptions]);

  return (
    <Dropdown
      trigger={TriggerElement}
      options={options}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      appendToDocumentBody
    />
  );
});
