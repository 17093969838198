import { removeSubQueryFromQuery } from '../../filters-compiler/removeSubQueryFromQuery';

export const addTagToQuery = ({ query, tagName }: {query: string; tagName: string;}) => {
  return `(${query}) OR tag:"${tagName}"`;
};

export const removeTagFromQuery = ({ query, tagName }: {query: string; tagName: string;}) => {
  let newQuery = query;

  if (query.includes(`tag:"${tagName}"`)) {
    newQuery = removeSubQueryFromQuery({ subQuery: `tag:"${tagName}"`, query });
  } else {
    newQuery = removeSubQueryFromQuery({ subQuery: `tag:${tagName}`, query });
  }

  return newQuery;
};
