import React, { useCallback, useEffect, useState } from 'react';

import { renameTags } from '../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/tag';
import { createToast } from '../../../shared/foreground/toasts.platform';
import type { GlobalTagsObject } from '../../../shared/types/tags';
import { Dialog } from './Dialog';
import styles from './Dialog.module.css';

export const MergeTagsDialog = ({ isOpen, setIsOpen, selectedTagIds, setSelectedTagIds, globalTagsObject }: {isOpen: boolean; setIsOpen: (v: boolean) => void; selectedTagIds: string[]; setSelectedTagIds: (v: string[]) => void; globalTagsObject: GlobalTagsObject; }) => {
  const [newTagName, setNewTagName] = useState('');

  const onClose = useCallback(() => {
    setIsOpen(false);
    setNewTagName('');
  }, [setIsOpen]);

  const onConfirm = useCallback(() => {
    if (!newTagName) {
      createToast({
        content: 'Please, enter a tag name',
        category: 'error',
      });
      return;
    }

    const prevTagNames = selectedTagIds.map((tagId) => globalTagsObject[tagId].name);
    renameTags({ prevTagNames, newTagName, options: { userInteraction: 'click' } });
    onClose();
    setSelectedTagIds([]);
  }, [globalTagsObject, newTagName, selectedTagIds, onClose, setSelectedTagIds]);

  useEffect(() => {
    const firstTagId = selectedTagIds[0];
    const firstTag = globalTagsObject[firstTagId];

    if (firstTag) {
      setNewTagName(firstTag.name);
    }
  }, [globalTagsObject, selectedTagIds]);

  if (isOpen) {
    return <div>
      <Dialog
        id="merge-tags"
        title={`Merge ${selectedTagIds.length} tags?`}
        actionTitle="Merge tags"
        cancelTitle="Cancel"
        action={onConfirm}
        cancelAction={onClose}>
          <div className={styles.inputWrapper}>
            <p>Name for merged tags</p>
            <input aria-label="tag name" value={newTagName} onChange={(e) => setNewTagName(e.target.value)} placeholder="Tag name" type="text" autoComplete="off" />
          </div>
      </Dialog>
    </div>;
  }

  return null;
};
